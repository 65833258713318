/** @format */

import _, {result} from "lodash";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "./styles/style.scss";
import $ from "jquery";
import "core-js/stable";
import "regenerator-runtime/runtime";
import anime from "animejs/lib/anime.es.js";
import "jquery-ui/ui/widgets/autocomplete.js";

import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken =
  "pk.eyJ1IjoiY3N0YWNreSIsImEiOiJja3ZsZG5zdmwxNTVzMnZ0a20wZDR4dG1xIn0.FwE9eabshpYKcDa3FZhVOw";

var construct = false;

class generalCall {
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  removeAllActive() {
    const backgroundImages = document.querySelectorAll(
      ".wallpaper-slider .image"
    );

    backgroundImages.forEach((image) => {
      image.classList.remove("active");
    });
  }

  setActive(image, index) {
    let backgroundImages = document.querySelectorAll(
      ".wallpaper-slider .image"
    );

    setTimeout(() => {
      index === 0
        ? backgroundImages[index - 1].classList.remove("active")
        : backgroundImages[index + 1].classList.remove("active");
      image.classList.add("active");
    }, 3000);
  }

  logicHome() {
    let images = document.querySelectorAll(".wallpaper-slider .image");
    let maxImage = images.length;
    images[0].classList.add("active");
    let index = 1;

    setInterval(() => {
      images.forEach((img) => {
        img.classList.remove("active");
      });

      images[index].classList.add("active");
      index = (index + 1) % images.length;
    }, 10000);
  }

  callZoomImage() {
    var modal = document.getElementById("s_modal");
    var icon_close = document.getElementById("s_ico_close");
    var modalImg = document.getElementById("s_img");

    let contentImg = document.querySelector(
      ".s_general_box .s_model_head > div:first-child"
    );
    let control = document.querySelector(".s_control_swiper_appartement");
    let contentWidth = contentImg.getBoundingClientRect().width;
    let contentHeight = contentImg.getBoundingClientRect().height;
    let contentLeft = contentImg.getBoundingClientRect().left;

    control.style.width = contentWidth + "px";
    control.style.height = contentHeight + "px";

    const observer = new ResizeObserver((entries) => {
      contentImg = document.querySelector(
        ".s_general_box .s_model_head > div:first-child"
      );
      control = document.querySelector(".s_control_swiper_appartement");
      contentWidth = contentImg.getBoundingClientRect().width;
      contentHeight = contentImg.getBoundingClientRect().height;
      contentLeft = contentImg.getBoundingClientRect().left;
      // Parcourez les entrées et récupérez les dimensions
      control.style.width = contentWidth + "px";
      control.style.height = contentHeight + "px";
    });

    // Commencez à observer l'élément cible
    observer.observe(document.querySelector("body"));

    // Get the image and insert it inside the modal - use its "alt" text as a caption

    const toggles = document.querySelectorAll(".s_content_app");
    toggles.forEach((toggle) => {
      toggle.addEventListener("click", () => {
        modal.style.display = "flex";
      });
    });

    // When the user clicks on <span> (x), close the modal
    icon_close.onclick = function () {
      // img01.className += " out";
      setTimeout(function () {
        modal.style.display = "none";
      }, 400);
    };
  }
  callApiGouv(isBuy) {
    // TRAITEMENT DE BASE
    let content_bloc = document.querySelector(".s_nodal_content");
    let close = document.querySelector(
      ".s_nodal_content > div:first-child > img"
    );
    let allLocalisation = [];

    var check = true;

    content_bloc.style.display = "none";
    close.addEventListener("click", () => {
      content_bloc.style.display = "none";
    });
    // TRAITEMENT DE BASE

    $("#adresse").autocomplete({
      source: function (request) {
        let buy;

        if (isBuy) {
          buy =
            "https://api-adresse.data.gouv.fr/search/?q=" +
            $("input[name='adresse']").val() +
            "&type=municipality&autocomplete=1";
        } else {
          buy =
            "https://api-adresse.data.gouv.fr/search/?q=" +
            $("input[name='adresse']").val() +
            "&type=housenumber&autocomplete=1";
        }

        $.ajax({
          url: buy,
          data: {q: request.term},
          dataType: "json",
          success: function (data) {
            let content_search = document.getElementById("content_search");
            let content_bloc = document.querySelector(".s_nodal_content");
            let search = document.getElementById("adresse");
            content_search.innerHTML = "";
            if (data.features.length <= 0) {
              content_search.innerHTML =
                '<div><div><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.99 22C6.46846 21.9945 1.99632 17.5149 2 11.9933C2.00368 6.47179 6.48179 1.99816 12.0033 2C17.5249 2.00184 22 6.47845 22 12C21.9967 17.5254 17.5154 22.0022 11.99 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.6845 16.444 4.10977 12.0425 4.08599C7.64111 4.06245 4.04732 7.59876 4 12V12.172ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="red"/></svg></div><div><h4>Votre recherche n\'a pas abouti</h4><p>Vous devez saisir une adresse valide</p></div></div>';
              content_bloc.style.display = "flex";
            } else if (data.features.length > 0) {
              content_bloc.style.display = "flex";
              for (let index = 0; index < data.features.length; index++) {
                content_search.innerHTML +=
                  '<div><div><svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" version="1.1" class="ui-components-17lzhwz"><path d="M18.0024 8.55107L10.0019 1L2 8.55102L3.1723 9.49916L10.0018 3.05449L16.83 9.49911L18.0024 8.55107Z"></path><path d="M5.49976 9.99951H3.99976V16.9995H8.99756L8.99756 13.4995L10.9976 13.4995V16.9995H15.9976V9.99951H14.4976V15.4995H12.4976V11.9995H7.49756L7.49756 15.4995H5.49976V9.99951Z"></path></svg></div><div><h4>' +
                  data.features[index]["properties"]["name"] +
                  "</h4><p>" +
                  data.features[index]["properties"]["label"] +
                  "</p></div></div>";
              }
              let elements = document.querySelectorAll(
                ".s_nodal_content > div:nth-child(2) > div"
              );
              elements.forEach((element) => {
                element.addEventListener("click", () => {
                  search.value = element.childNodes[1].childNodes[1].innerHTML;
                  content_bloc.style.display = "none";
                  if (isBuy) {
                    let indexApi = [].indexOf.call(elements, element);
                    let postcode = data.features[indexApi].properties.postcode;
                    if ("localisation" in localStorage && check) {
                      allLocalisation = [];
                      if (localStorage.getItem("localisation") != "[]") {
                        let currentLocal = localStorage.getItem("localisation");
                        let contentLocal = currentLocal.replace(/\D/g, "");
                        let zipcode = contentLocal.toString();
                        let getZipcodeValue = zipcode.match(/.{1,5}/g);
                        for (
                          let index = 0;
                          index < getZipcodeValue.length;
                          index++
                        ) {
                          allLocalisation.push(getZipcodeValue[index]);
                        }

                        check = false;
                      }
                    }

                    allLocalisation.push(postcode);
                    localStorage.setItem(
                      "localisation",
                      JSON.stringify(allLocalisation)
                    );

                    search.value = "";
                    result = document.getElementById("result");
                    result.innerHTML +=
                      "<b>" +
                      element.childNodes[1].childNodes[1].innerHTML +
                      '<img data-opt-src="assets/icons/close_white.svg" alt=""></b>';

                    let adresses = document.querySelectorAll(".result img");
                    adresses.forEach((adresse) => {
                      adresse.addEventListener("click", () => {
                        let indexData = [].indexOf.call(adresses, adresse);
                        adresse.parentElement.remove();
                        if (allLocalisation.length != 1) {
                          allLocalisation.splice(indexData, 1);
                        } else {
                          allLocalisation = [];
                        }
                        localStorage.setItem(
                          "localisation",
                          JSON.stringify(allLocalisation)
                        );
                      });
                    });
                  }
                });
              });
            }
          },
        });
      },
    });
  }
  callAnimation(index) {
    switch (index) {
      case 1:
        let agents = document.querySelectorAll(
          ".s_card_agent > div:first-child"
        );
        agents.forEach((agent) => {
          agent.addEventListener("mouseenter", (e) => {
            agent.lastElementChild.classList.remove("s_unactive_agent");
            agent.lastElementChild.classList.add("s_active_agent");
          });
          agent.addEventListener("mouseleave", (e) => {
            agent.lastElementChild.classList.remove("s_active_agent");
            agent.lastElementChild.classList.add("s_unactive_agent");
          });
        });
        break;

      default:
        break;
    }
  }
  callEditor() {
    var toolbarOptions = [
      ["bold"],
      ["blockquote"],

      [{header: 1}, {header: 2}],
      [{list: "bullet"}],

      [{header: [1, 2, false]}],

      [{color: []}, {background: []}],
      ["link"],

      ["clean"],
    ];
    var options = {
      debug: "false",
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: "Rédiger votre article ..",
      readOnly: false,
      theme: "snow",
    };
    var editor = new Quill("#s_editor", options);
  }

  callMenu() {
    let menus = document.querySelector(".s_menu_svg"),
      label = document.querySelector(".s_menu_svg input[type=checkbox]"),
      logo = document.querySelector(".s_navbar div:first-child img"),
      menu_nodal = document.querySelector(".s_menu_modal"),
      filterParis = document.querySelectorAll(".paris"),
      filterStrasbourg = document.querySelectorAll(".strasbourg");

    (filterParis = document.querySelectorAll(".paris")),
      (filterStrasbourg = document.querySelectorAll(".strasbourg"));

    filterParis.forEach((element) => {
      element.addEventListener("click", () => {
        localStorage.setItem(
          "localisation",
          JSON.stringify([
            "75001",
            "75002",
            "75003",
            "75004",
            "75005",
            "75006",
            "75007",
            "75008",
            "75009",
            "75010",
            "75011",
            "75012",
            "75013",
            "75014",
            "75015",
            "75016",
            "75017",
            "75018",
            "75019",
            "75020",
          ])
        );
      });
    });
    filterStrasbourg.forEach((element) => {
      element.addEventListener("click", () => {
        localStorage.setItem(
          "localisation",
          JSON.stringify(["67000", "67100", "67200"])
        );
      });
    });

    window.addEventListener("load", (event) => {
      anime({
        targets: ".s_load",
        duration: "1000",
        opacity: "0",
        easing: "easeInOutQuad",
      });
      setTimeout(() => {
        document.querySelector(".s_load").style.display = "none";
      }, "1000");
    });

    logo.addEventListener("click", () => {
      // window.location.href = "/client/von-de-kay/";
      window.location.href = "/";
    });

    menus.addEventListener("click", () => {
      if (label.checked) {
        menu_nodal.classList.remove("s_active_menu");
        menu_nodal.classList.add("s_unactive_menu");
        document.querySelector("html").style.overflow = "visible";
      } else {
        menu_nodal.classList.remove("s_unactive_menu");
        menu_nodal.classList.add("s_active_menu");
        document.querySelector("html").style.overflow = "hidden";
        let reseau = document.querySelector(".s_reseaux");
        let menu = document.querySelector(".s_menu_svg");

        menu.style.mixBlendMode = "difference";
        reseau.style.mixBlendMode = "difference";
      }
    });
  }
  callAxeptio() {
    window.axeptioSettings = {
      clientId: "60a275c2e34b5e74720e5388",
      cookiesVersion: "client_von_de_kay",
    };

    (function (d, s) {
      var t = d.getElementsByTagName(s)[0],
        e = d.createElement(s);
      e.async = true;
      e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");
    void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function (axeptio) {
      axeptio.on("cookies:complete", function (choices) {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-8JRM8W1NLP");
      });
    });
    void 0 === window._axcb && (window._axcb = []);
    window._axcb.push(function (axeptio) {
      axeptio.on("cookies:complete", function (choices) {
        if (choices.GoogleAnalytics) {
        }
      });
    });
  }
}

class navigationCall {
  callDelay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  callGetAbsolutePositionY(el) {
    return (
      document.getElementById(el).getBoundingClientRect().top +
      document.documentElement.scrollTop -
      1
    );
  }

  async callInit(page) {
    const newLocal = this;

    let numberCurrentStep = document.querySelector(
      ".s_navbar div:nth-child(2) > div > span > b"
    );
    let numberStep = document.querySelector(
      ".s_navbar div:nth-child(2) > div span"
    );
    let arrowSelectBack = document.getElementById("arrowSelectBack");
    let arrowSelectNext = document.getElementById("arrowSelectNext");
    let currentStep = 0;
    let currentAnime = "";

    let firstElement = "";
    let secondElement = "";
    let thirdlyElement = "";
    let fourthElement = "";
    let fifthElement = "";
    let SixthElement = "";

    //INIT
    window.addEventListener("scroll", function (e) {
      switch (page) {
        case 1:
          firstElement = newLocal.callGetAbsolutePositionY("1");
          secondElement = newLocal.callGetAbsolutePositionY("2");
          thirdlyElement = newLocal.callGetAbsolutePositionY("3");
          fourthElement = newLocal.callGetAbsolutePositionY("4");
          fifthElement = newLocal.callGetAbsolutePositionY("5");
          SixthElement = newLocal.callGetAbsolutePositionY("6");

          if (
            window.pageYOffset >= firstElement &&
            window.pageYOffset <= secondElement
          ) {
            let reseau = document.querySelector(".s_reseaux");
            let menu = document.querySelector(".s_menu_svg");

            menu.style.mixBlendMode = "initial";
            reseau.style.mixBlendMode = "initial";
            if (currentStep > 1) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 1) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 1;

            if (numberCurrentStep.innerHTML != "1") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#";
              arrowSelectNext.href = "#2";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "1";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#C9C1B6";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#325a66";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          } else if (
            window.pageYOffset >= secondElement &&
            window.pageYOffset <= thirdlyElement
          ) {
            let reseau = document.querySelector(".s_reseaux");
            let menu = document.querySelector(".s_menu_svg");

            menu.style.mixBlendMode = "difference";
            reseau.style.mixBlendMode = "difference";
            if (currentStep > 2) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 2) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 2;
            if (numberCurrentStep.innerHTML != "2") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#";
              arrowSelectNext.href = "#3";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "2";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#325a66";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#325a66";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          } else if (
            window.pageYOffset >= thirdlyElement &&
            window.pageYOffset <= fourthElement
          ) {
            if (currentStep > 3) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 3) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 3;
            if (numberCurrentStep.innerHTML != "3") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#2";
              arrowSelectNext.href = "#4";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "3";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#325a66";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#325a66";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          } else if (
            window.pageYOffset >= fourthElement &&
            window.pageYOffset <= fifthElement
          ) {
            if (currentStep > 4) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 4) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 4;
            if (numberCurrentStep.innerHTML != "4") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#3";
              arrowSelectNext.href = "#5";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "4";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#325a66";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#325a66";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          } else if (
            window.pageYOffset >= fifthElement &&
            window.pageYOffset <= SixthElement
          ) {
            if (currentStep > 5) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 5) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 5;
            if (numberCurrentStep.innerHTML != "5") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#4";
              arrowSelectNext.href = "#5";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "5";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#325a66";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#C9C1B6";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          }
          break;
        case 2:
          firstElement = newLocal.callGetAbsolutePositionY("1");
          secondElement = newLocal.callGetAbsolutePositionY("2");
          thirdlyElement = newLocal.callGetAbsolutePositionY("3");

          if (
            window.pageYOffset >= firstElement &&
            window.pageYOffset <= secondElement
          ) {
            if (currentStep > 1) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 1) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 1;

            if (numberCurrentStep.innerHTML != "1") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#";
              arrowSelectNext.href = "#2";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "1";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#C9C1B6";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#325a66";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          } else if (
            window.pageYOffset >= secondElement &&
            window.pageYOffset <= thirdlyElement
          ) {
            if (currentStep > 2) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 2) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 2;
            if (numberCurrentStep.innerHTML != "2") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#";
              arrowSelectNext.href = "#3";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "2";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#325a66";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#325a66";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          } else if (window.pageYOffset >= thirdlyElement) {
            if (currentStep > 3) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 3) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 3;
            if (numberCurrentStep.innerHTML != "3") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#2";
              arrowSelectNext.href = "#3";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "3";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#325a66";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#C9C1B6";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          }
          break;
        case 3:
          firstElement = newLocal.callGetAbsolutePositionY("1");
          if (window.pageYOffset >= firstElement) {
            if (currentStep > 1) {
              currentAnime = "s_animated_top";
            } else if (currentStep < 1) {
              currentAnime = "s_animated_bottom";
            }
            currentStep = 1;

            if (numberCurrentStep.innerHTML != "1") {
              numberCurrentStep.classList.add(currentAnime);
              arrowSelectBack.href = "#";
              arrowSelectNext.href = "#";
              setTimeout(function () {
                numberCurrentStep.innerHTML = "1";
                numberStep.style.color = "#ececea";
                numberCurrentStep.style.color = "#ececea";
                document.querySelector("#arrowSelectBack > svg").style.fill =
                  "#ececea";
                document.querySelector("#arrowSelectNext > svg").style.fill =
                  "#ececea";
              }, 300);
              setTimeout(function () {
                numberCurrentStep.classList.remove(
                  "s_animated_top",
                  "s_animated_bottom"
                );
              }, 1000);
            }
          }
      }
    });
  }
}

class loaderCall {
  callInit() {
    let Y = window.scrollY + 1;
    window.scrollTo(0, Y);

    document.documentElement.className += " optimole_has_js";
    (function (w, d) {
      var b = d.getElementsByTagName("head")[0];
      var s = d.createElement("script");
      var v = "IntersectionObserver" in w ? "_no_poly" : "";
      s.async = true; // This includes the script as async.
      s.src =
        "https://d5jmkjjpb7yfg.cloudfront.net/v2/latest/optimole_lib" +
        v +
        ".min.js";
      w.optimoleData = {
        key: "mlhe5eszwjzr",
        quality: "70",
        lazyloadOnly: "optimole-lazy-only",
        nativeLazyload: true,
        scalingDisabled: false,
        watchClasses: [],
        backgroundLazySelectors: "",
        backgroundReplaceClasses: ["my-background-loaded-img"],
        network_optimizations: true,
        ignoreDpr: true,
      };
      b.appendChild(s);
    })(window, document);

    document.addEventListener("DOMContentLoaded", function () {
      document.body.className = document.body.className.replace(
        "optimole-no-script",
        ""
      );
      if (
        "loading" in HTMLImageElement.prototype &&
        Object.prototype.hasOwnProperty.call(optimoleData, "nativeLazyload") &&
        optimoleData.nativeLazyload === true
      ) {
        const images = document.querySelectorAll('img[loading="lazy"]');
        images.forEach(function (img) {
          if (!img.dataset.optSrc) {
            return;
          }
          img.src = img.dataset.optSrc;
          delete img.dataset.optSrc;
        });
      }
    });
  }
}

class slideCall {
  callThumbnails() {
    lightGallery(document.getElementById("equipe"), {
      animateThumb: false,
      zoomFromOrigin: false,
      allowMediaOverlap: true,
      toggleThumb: true,
    });
  }
  initCall() {
    var appartment = new Swiper(".s_slide_appartement", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 80,
      effect: "fade",
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      navigation: {
        nextEl: ".swiper-right_a",
        prevEl: ".swiper-left_a",
      },
      breakpoints: {
        1800: {
          spaceBetween: 80,
          slidesPerView: 1,
        },
        560: {
          slidesPerView: 1,
        },
      },
    });
    var model = new Swiper(".s_img_model ", {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".s_next_thumb",
        prevEl: ".s_prev_thumb",
      },
      freeMode: true,
      autoplay: {
        delay: 15000,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      navigation: {
        nextEl: ".swiper-left_b",
        prevEl: ".swiper-right_b",
      },
    });
    // var agent = new Swiper(".s_slide_agent", {
    //     loop: true,
    //     slidesPerView: 1,
    //     keyboard: {
    //         enabled: true,
    //         onlyInViewport: false,
    //     },
    //     navigation: {
    //         nextEl: ".swiper-right_ag",
    //         prevEl: ".swiper-left_ag",
    //     },
    //     breakpoints: {
    //         1800: {
    //             slidesPerView: 3,
    //             spaceBetween: 80,
    //         },
    //         560: {
    //             slidesPerView: 3,
    //             spaceBetween: 80,
    //         }
    //     },
    // });
    var reviews = new Swiper(".s_slide_reviews", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 60,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      navigation: {
        nextEl: ".swiper-left_r",
        prevEl: ".swiper-right_r",
      },
      breakpoints: {
        1800: {
          spaceBetween: 60,
          slidesPerView: 1,
        },
        560: {
          slidesPerView: 1,
        },
      },
    });
    const zoom = new Swiper(".zoomSlide", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 20,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      breakpoints: {
        1800: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        560: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },

      navigation: {
        nextEl: ".swiper-left_r",
        prevEl: ".swiper-right_r",
      },
    });
    var partners = new Swiper(".s_slide_partners", {
      loop: true,
      slidesPerView: 3,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      breakpoints: {
        1800: {
          slidesPerView: 5,
        },
        560: {
          slidesPerView: 5,
        },
      },
    });
  }
}

class ajaxCall {
  callEstimation() {
    $("#estimationform").submit(function () {
      if (localStorage.getItem("step_1") === "Une maison") {
        var dataForm = [
          localStorage.getItem("step_1"),
          localStorage.getItem("step_2"),
          localStorage.getItem("step_3"),
          localStorage.getItem("step_4_0"),
          localStorage.getItem("step_4_1"),
          localStorage.getItem("step_4_2"),
          localStorage.getItem("step_5"),
          localStorage.getItem("step_6_0"),
          localStorage.getItem("step_6_1"),
          localStorage.getItem("step_7"),
          localStorage.getItem("step_8"),
          localStorage.getItem("step_9"),
          localStorage.getItem("step_10"),
          localStorage.getItem("step_11"),
          localStorage.getItem("adresse"),
        ];
      } else {
        var dataForm = [
          localStorage.getItem("step_1"),
          localStorage.getItem("step_2"),
          localStorage.getItem("step_3"),
          localStorage.getItem("step_4_0"),
          localStorage.getItem("step_4_1"),
          localStorage.getItem("step_4_2"),
          localStorage.getItem("step_5"),
          localStorage.getItem("step_6_0"),
          localStorage.getItem("step_6_1"),
          localStorage.getItem("step_7"),
          localStorage.getItem("step_8"),
          localStorage.getItem("step_9_0"),
          localStorage.getItem("step_9_0_0"),
          localStorage.getItem("step_9_1"),
          localStorage.getItem("step_9_1_0"),
          localStorage.getItem("step_9_2"),
          localStorage.getItem("step_9_2_0"),
          localStorage.getItem("step_10"),
          localStorage.getItem("step_11"),
          localStorage.getItem("adresse"),
        ];
      }
      var a = $(this).attr("action");
      $("#message").slideUp(750, function () {
        $("#message").hide();
        $("#estimation").attr("disabled", "disabled");
        $.post(
          a,
          {
            nom: $("#nom").val(),
            prenom: $("#prenom").val(),
            email: $("#mail").val(),
            phone: $("#phone").val(),

            data: dataForm,
          },
          function (a) {
            const res = $.parseJSON(a);
            document.getElementById("message").innerHTML = res.data;

            $("#message").slideDown("slow");
            $("#estimation").removeAttr("disabled");
            setTimeout(() => {
              if (res.code === 200) {
                localStorage.clear();
                document.location.href = "/felicitation";
              }
            }, 300);
          }
        );
      });
      return false;
    });
    $("#contactform input, #contactform textarea").keyup(function () {
      $("#message").slideUp(1500);
    });
  }
  callContact() {
    $("#s_contact_send").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      $(".sucess_message").remove();

      // TRAITEMENT
      let verification_vondekay = document
        .getElementById("robot")
        .getAttribute("placeholder")
        .replace(/^\D+/g, "")
        .split(" + ");

      var a = "src/php/form/contact.php";
      var postObj = new FormData();

      postObj.append("nom", $("#nom").val());
      postObj.append("prenom", $("#prenom").val());
      postObj.append("mail", $("#mail").val());
      postObj.append("phone", $("#phone").val());
      postObj.append("description", $("#description").val());

      postObj.append("verification_client", $("#robot").val());
      postObj.append("verification_vondekay", verification_vondekay);

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.includes("mail_vide")) {
            $("#s_contact_message").show();
            $("#s_contact_message").append(
              '<div class="error_message">Vous devez renseigner votre mail.</div>'
            );
          } else if (data.includes("mail_error")) {
            $("#s_contact_message").show();
            $("#s_contact_message").append(
              '<div class="error_message">Le mail que vous avez renseigné n\'est pas valide.</div>'
            );
          } else if (data.includes("description_vide")) {
            $("#s_contact_message").show();
            $("#s_contact_message").append(
              '<div class="error_message">Vous n\'avez pas renseigné de message.</div>'
            );
          } else if (data.includes("verification_vide")) {
            $("#s_contact_message").show();
            $("#s_contact_message").append(
              '<div class="error_message">Vous n\'avez pas remplis le Captcha.</div>'
            );
          } else if (data.includes("verification_calcul")) {
            $("#s_contact_message").show();
            $("#s_contact_message").append(
              '<div class="error_message">Le résultat que vous avez inscrit est erroné.</div>'
            );
          } else if (data.includes("mail_send")) {
            $("#s_contact_message").show();
            $("#s_contact_message").append(
              '<div class="sucess_message">Votre message a bien été envoyé !</div>'
            );
            document.getElementById("robot").placeholder =
              "Combien fait " +
              Math.floor(Math.random() * 6) +
              " + " +
              Math.floor(Math.random() * 6);
          }
        },
      });
    });
  }
  callConnexionClient() {
    $("#connexionButton").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "src/php/form/connexion.php";
      var postObj = new FormData();
      postObj.append("mailAdmin", $("#mailAdmin").val());
      postObj.append("passwordBackOffice", $("#passwordBackOffice").val());

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.includes("name")) {
            $("#messageConnect").show();
            $("#messageConnect").append(
              '<div class="error_message"></i>Vous devez renseigner votre mail</div>'
            );
          } else if (data.includes("password")) {
            $("#messageConnect").show();
            $("#messageConnect").append(
              '<div class="error_message"></i>Vous devez renseigner votre mot de passe</div>'
            );
          } else if (data.includes("mdp")) {
            $("#messageConnect").show();
            $("#messageConnect").append(
              '<div class="error_message"></i>Mot de passe incorrect.</div>'
            );
          } else if (data.includes("error")) {
            $("#messageConnect").show();
            $("#messageConnect").append(
              '<div class="error_message"></i>Nom d\'utilisateur ou mot de passe invalide.</div>'
            );
          } else if (data.includes("succes")) {
            $("#messageConnect").show();
            $("#messageConnect").append(
              '<div class="sucess_message">Connexion réussi !</div>'
            );
            document.location.href = "/tableau-board";
          }
        },
      });
    });
  }
  callEditNewBlog() {
    $("#s_edit_blog_send").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "src/php/functions/table/edit_blog.php";
      var container = document.querySelector(".ql-editor");
      var postObj = new FormData();

      postObj.append("id", $("#id_blog").val());
      postObj.append("author", $("#edit_auteur").val());
      postObj.append("title", $("#edit_title").val());
      postObj.append("short_desc", $("#edit_shortdesc").val());
      postObj.append("content", container.innerHTML);
      postObj.append(
        "big_img",
        document.getElementById("edit_big_img").options[
          document.getElementById("edit_big_img").selectedIndex
        ].value
      );

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.includes("image")) {
            $("#s_message_edit_blog").show();
            $("#s_message_edit_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre une image</div>'
            );
          } else if (data.includes("title")) {
            $("#s_message_edit_blog").show();
            $("#s_message_edit_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre un titre</div>'
            );
          } else if (data.includes("author")) {
            $("#s_message_edit_blog").show();
            $("#s_message_edit_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez renseigner le nom de l\'auteur</div>'
            );
          } else if (data.includes("short_desc")) {
            $("#s_message_edit_blog").show();
            $("#s_message_edit_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre une petite description</div>'
            );
          } else if (data.includes("content")) {
            $("#s_message_edit_blog").show();
            $("#s_message_edit_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire l\'article mdr</div>'
            );
          } else if (data.includes("succes")) {
            $("#s_message_edit_blog").show();
            $("#s_message_edit_blog").append(
              '<div class="sucess_message"><i class="fal fa-thumbs-up"></i>L\'article a été ajouté</div>'
            );
            document.location.href = "/vos-blogs";
          }
        },
      });
    });
  }
  callAddNewBlog() {
    $("#s_add_blog_send").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "src/php/functions/table/add_blog.php";
      var container = document.querySelector(".ql-editor");
      var postObj = new FormData();

      postObj.append("author", $("#add_auteur").val());
      postObj.append("title", $("#add_title").val());
      postObj.append("image", $("#add_image")[0].files[0]);
      postObj.append("short_desc", $("#add_shortdesc").val());
      postObj.append("content", container.innerHTML);
      postObj.append(
        "big_img",
        document.getElementById("add_big_img").options[
          document.getElementById("add_big_img").selectedIndex
        ].value
      );

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.includes("image")) {
            $("#s_message_add_blog").show();
            $("#s_message_add_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre une image</div>'
            );
          } else if (data.includes("title")) {
            $("#s_message_add_blog").show();
            $("#s_message_add_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre un titre</div>'
            );
          } else if (data.includes("author")) {
            $("#s_message_add_blog").show();
            $("#s_message_add_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez renseigner le nom de l\'auteur</div>'
            );
          } else if (data.includes("short_desc")) {
            $("#s_message_add_blog").show();
            $("#s_message_add_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre une petite description</div>'
            );
          } else if (data.includes("content")) {
            $("#s_message_add_blog").show();
            $("#s_message_add_blog").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire l\'article mdr</div>'
            );
          } else if (data.includes("succes")) {
            $("#s_message_add_blog").show();
            $("#s_message_add_blog").append(
              '<div class="sucess_message"><i class="fal fa-thumbs-up"></i>L\'article a été ajouté</div>'
            );
            document.location.href = "/vos-blogs";
          }
        },
      });
    });
  }
  callAddNewEquipe() {
    $("#s_add_equipe_send").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "src/php/functions/table/add_equipe.php";
      var postObj = new FormData();

      postObj.append("name", $("#add_name").val());
      postObj.append("job", $("#add_job").val());
      postObj.append("image", $("#add_image").val());
      postObj.append("shortdesc", $("#add_shortdesc").val());
      postObj.append("loca", $("#add_loca").val());
      postObj.append("number", $("#add_number").val());
      postObj.append("mail", $("#add_mail").val());
      postObj.append("image", $("#add_image")[0].files[0]);

      postObj.append(
        "status",
        document.getElementById("status").options[
          document.getElementById("status").selectedIndex
        ].value
      );

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.includes("image")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre une image</div>'
            );
          } else if (data.includes("name")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre un nom</div>'
            );
          } else if (data.includes("job")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez renseigner un métier ou statut</div>'
            );
          } else if (data.includes("shortdesc")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire la description</div>'
            );
          } else if (data.includes("loca")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez renseigner un localisation</div>'
            );
          } else if (data.includes("number")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire un numéro</div>'
            );
          } else if (data.includes("mail")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire un mail</div>'
            );
          } else if (data.includes("succes")) {
            $("#s_message_add_equipe").show();
            $("#s_message_add_equipe").append(
              '<div class="sucess_message"><i class="fal fa-thumbs-up"></i>L\'agent ou partenaire a été ajouté</div>'
            );
            document.location.href = "/vos-equipes";
          }
        },
      });
    });
  }
  callEditNewEquipe() {
    $("#s_edit_equipe_send").click(function (e) {
      e.preventDefault();
      $(".error_message").remove();
      var a = "src/php/functions/table/edit_equipe.php";
      var postObj = new FormData();

      postObj.append("id", $("#id_equipe").val());

      postObj.append("name", $("#edit_name").val());
      postObj.append("job", $("#edit_job").val());
      postObj.append("image", $("#edit_image")[0].files[0]);
      postObj.append("shortdesc", $("#edit_shortdesc").val());
      postObj.append("loca", $("#edit_loca").val());
      postObj.append("number", $("#edit_number").val());
      postObj.append("mail", $("#edit_mail").val());
      postObj.append(
        "status",
        document.getElementById("edit_status").options[
          document.getElementById("edit_status").selectedIndex
        ].value
      );

      $.ajax({
        url: a,
        type: "POST",
        data: postObj,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.includes("name")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez mettre un nom</div>'
            );
          } else if (data.includes("job")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez renseigner un métier ou statut</div>'
            );
          } else if (data.includes("shortdesc")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire la description</div>'
            );
          } else if (data.includes("loca")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez renseigner un localisation</div>'
            );
          } else if (data.includes("number")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire un numéro</div>'
            );
          } else if (data.includes("mail")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="error_message"><i class="fas fa-exclamation-triangle"></i>Vous devez écrire un mail</div>'
            );
          } else if (data.includes("succes")) {
            $("#s_message_edit_equipe").show();
            $("#s_message_edit_equipe").append(
              '<div class="sucess_message"><i class="fal fa-thumbs-up"></i>L\'agent ou partenaire a été ajouté</div>'
            );
            // document.location.href = "/vos-equipes";
          }
        },
      });
    });
  }
}

class Appartement {
  initCall() {
    const local = [
      "types",
      "localisation",
      "rooms",
      "minarea",
      "maxarea",
      "minprice",
      "maxprice",
    ];

    if (this.callIsLocalExist(local[1])) {
      let allLocalisation = localStorage.getItem(local[1]).replace(/\D/g, "");
      allLocalisation = allLocalisation.match(/.{1,5}/g);
    }
    for (let index = 0; index < local.length; index++) {
      if (this.callIsLocalExist(local[index])) {
        let content;
        let currentLocal = localStorage.getItem(local[index]);
        let contentLocal = currentLocal.replace(/\D/g, "");

        switch (index) {
          case 0:
            contentLocal = parseInt(contentLocal);
            let types = [];
            contentLocal = currentLocal.split('"');
            for (let index = 0; index < contentLocal.length; index++) {
              let matches = contentLocal[index].match(/\d+/g);
              if (matches != null) {
                content = document.querySelectorAll(".types > b input");
                for (let z = 0; z < content.length; z++) {
                  if (content[z].value === contentLocal[index]) {
                    content[z].checked = true;
                  }
                }
              }
            }
            break;
          case 1:
            if (!isNaN(contentLocal) && contentLocal.length > 3) {
              let zipcode = contentLocal.toString();
              let getZipcodeValue = zipcode.match(/.{1,5}/g);
              for (let index = 0; index < getZipcodeValue.length; index++) {
                document.querySelector(".result").innerHTML +=
                  "<b>" +
                  getZipcodeValue[index] +
                  '<img data-opt-src="assets/icons/close_white.svg" alt=""></b>';
              }
            }
            break;
          case 2:
            contentLocal = parseInt(contentLocal);
            content = document.querySelectorAll(
              ".s_filter > div > div > div.pieces input"
            );
            content[contentLocal].checked = true;
            content[contentLocal].nextElementSibling.style.color = "white";
            break;
          case 3:
            contentLocal = parseInt(contentLocal);
            content = [
              document.getElementById("minarea"),
              document.getElementById("maxarea"),
            ];
            content[0].value = contentLocal;
            break;
          case 4:
            contentLocal = parseInt(contentLocal);
            content = [
              document.getElementById("minarea"),
              document.getElementById("maxarea"),
            ];
            content[1].value = contentLocal;
            break;
          case 5:
            contentLocal = parseInt(contentLocal);
            content = [
              document.getElementById("minprice"),
              document.getElementById("maxprice"),
            ];
            content[0].value = contentLocal;
            break;
          case 6:
            contentLocal = parseInt(contentLocal);
            content = [
              document.getElementById("minprice"),
              document.getElementById("maxprice"),
            ];
            content[1].value = contentLocal;
            break;
        }
      }
    }

    this.callFilterBack();

    let adresses = document.querySelectorAll(".result img");
    adresses.forEach((adresse) => {
      adresse.addEventListener("click", () => {
        let allLocalisation = localStorage.getItem(local[1]).replace(/\D/g, "");
        allLocalisation = allLocalisation.match(/.{1,5}/g);
        let indexData = [].indexOf.call(adresses, adresse);
        adresse.parentElement.remove();
        if (allLocalisation.length != 1) {
          allLocalisation.splice(indexData, 1);
        } else {
          allLocalisation = [];
        }
        localStorage.setItem("localisation", JSON.stringify(allLocalisation));
      });
    });
  }

  callFilterBack() {
    let url = "src/php/form/filter.php",
      localisation,
      type = document.querySelectorAll(".types > b input"),
      rooms = document.querySelectorAll("div.pieces input"),
      allType = [],
      category;

    if (!("order" in localStorage)) {
      localStorage.setItem("order", "id_recent");
    }

    const newLocal = this;

    // PROCESS
    if (location.pathname.split("/")[1] === "acheter") {
      category = 1;
    } else {
      category = 2;
    }
    for (let a = 0; a < rooms.length; a++) {
      if (rooms[a].checked) {
        localStorage.setItem("rooms", JSON.stringify(rooms[a].value));
      }
    }

    for (let b = 0; b < type.length; b++) {
      if (type[b].checked) {
        allType.push(type[b].value.toString());
      }
    }
    localStorage.setItem("types", JSON.stringify(allType));
    let minArea = parseInt(document.getElementById("minarea").value),
      maxArea = parseInt(document.getElementById("maxarea").value);
    if (isNaN(minArea)) {
      localStorage.setItem("minarea", "");
    } else {
      localStorage.setItem("minarea", JSON.stringify(minArea));
    }
    if (isNaN(maxArea)) {
      localStorage.setItem("maxarea", "");
    } else {
      localStorage.setItem("maxarea", JSON.stringify(maxArea));
    }
    localStorage.setItem("minprice", JSON.stringify(minPrice));
    localStorage.setItem("maxprice", JSON.stringify(maxPrice));
    let minPrice = parseInt(document.getElementById("minprice").value),
      maxPrice = parseInt(document.getElementById("maxprice").value);
    if (isNaN(minPrice)) {
      localStorage.setItem("minprice", "");
    } else {
      localStorage.setItem("minprice", JSON.stringify(minPrice));
    }
    if (isNaN(maxPrice)) {
      localStorage.setItem("maxprice", "");
    } else {
      localStorage.setItem("maxprice", JSON.stringify(maxPrice));
    }

    for (let indexLocal = 0; indexLocal < localStorage.length; indexLocal++) {
      switch (localStorage.key(indexLocal)) {
        case "localisation":
          if (JSON.parse(localStorage.getItem("localisation")).length > 0) {
            localisation = localStorage.getItem("localisation");
          } else {
            localisation = "reset";
          }
          break;
      }
    }

    $.ajax({
      url: url,
      type: "POST",
      data: {
        types: localStorage.getItem("types"),
        room: localStorage.getItem("rooms"),
        minarea: localStorage.getItem("minarea"),
        maxarea: localStorage.getItem("maxarea"),
        minprice: localStorage.getItem("minprice"),
        maxprice: localStorage.getItem("maxprice"),
        order: localStorage.getItem("order"),
        allLocalisation: localisation,
      },
      dataType: "json",
      cache: false,
      success: function (data) {
        newLocal.callInfinityScroll(data);
      },
    });
  }

  callIsLocalExist(local) {
    if (local in localStorage) {
      return true;
    } else {
      return false;
    }
  }

  callMenuFilter() {
    window.mobileAndTabletCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    const isMobile = mobileAndTabletCheck();
    const newLocal = this;
    let dropdown = document.querySelectorAll(".s_filter > div"),
      allType = [],
      type = document.querySelectorAll(".types > b input"),
      rooms = document.querySelectorAll("div.pieces input"),
      order = document.querySelector(".s_order_by"),
      chooseOrder = document.querySelectorAll(".s_order_by > div > p");

    chooseOrder.forEach((choose) => {
      choose.addEventListener("click", () => {
        localStorage.setItem("order", choose.classList.value);
        this.callFilterBack(choose.classList.value);
      });
    });

    rooms.forEach((room) => {
      room.addEventListener("click", () => {
        for (let i = 0; i < rooms.length; ++i) {
          rooms[i].checked = false;
          rooms[i].nextElementSibling.style.color = "#325a66";
        }
        room.checked = true;
        room.nextElementSibling.style.color = "white";
      });
    });

    if (!isMobile) {
      order.addEventListener("mouseenter", function () {
        document.querySelector(".s_order_by").childNodes[3].style.display =
          "block";
      });
      order.addEventListener("mouseleave", function () {
        document.querySelector(".s_order_by").childNodes[3].style.display =
          "none";
      });
    }

    //DETECTION OUT VIEWPORT DES DROP
    for (let index = 0; index < dropdown.length; index++) {
      if (!isMobile) {
        dropdown[index].addEventListener("mouseenter", function () {
          dropdown[index].children[1].style.display = "block";
          dropdown[index].children[1].style.opacity = "1";
          let sizeDropdownInViewport =
            document.documentElement.clientWidth -
            dropdown[index].children[1].getBoundingClientRect().left;
          if (
            sizeDropdownInViewport < dropdown[index].children[1].clientWidth
          ) {
            let toRezizeDropdown =
              dropdown[index].children[1].clientWidth -
              (document.documentElement.clientWidth -
                dropdown[index].children[1].getBoundingClientRect().left);
            dropdown[index].children[1].style.transform =
              "translateX(-" + (toRezizeDropdown + 40) + "px)";
          }
        });
        dropdown[index].addEventListener("mouseleave", function () {
          dropdown[index].children[1].style.opacity = "0";
          dropdown[index].children[1].style.display = "none";
        });
      }

      if (isMobile) {
        let orderButton = document.getElementById("order_mobile"),
          filterButton = document.getElementById("filter_mobile"),
          closeButton = document.querySelector(".close_filter"),
          check = false;

        closeButton.addEventListener("click", function () {
          document.querySelector(".s_filter").style.top = "100%";
        });

        orderButton.addEventListener("click", function () {
          if (!check) {
            document.querySelector(".s_order_by > div").style.right = "65px";
            check = true;
          } else {
            document.querySelector(".s_order_by > div").style.right = "-100%";
            check = false;
          }
        });

        filterButton.addEventListener("click", function () {
          document.querySelector(".s_filter").style.zIndex = "20";
          document.querySelector(".s_filter").style.top = "40px";
        });
      }

      dropdown[index].children[1].lastElementChild.addEventListener(
        "click",
        function () {
          if (isMobile) {
            document.querySelector(".s_filter").style.top = "100%";
            document.querySelector(".s_filter").style.zIndex = "-1";
          } else {
            dropdown[index].children[1].style.display = "none";
          }
          newLocal.callFilterBack();
        }
      );
    }
  }
  callInfinityScroll(array_app) {
    document.querySelector(".s_appartement").remove();
    const el = document.createElement("div");
    el.classList.add("s_appartement");
    const filter = document.querySelector(".s_noresult");
    filter.after(el);
    let app_section = document.querySelector(".s_appartement");

    if (array_app.length === 0) {
      this.callCountAppartement(0);
      document.querySelector(".s_noresult").style.display = "flex";
    } else {
      document.querySelector(".s_noresult").style.display = "none";
      this.callCountAppartement(array_app.length);
      let number = 3;
      let index = 0;
      this.callAppartement(array_app, app_section, index, number);
      window.addEventListener("scroll", () => {
        const {scrollTop, scrollHeight, clientHeight} =
          document.documentElement;
        const container = document.querySelector(".s_appartement").offsetHeight;

        if (clientHeight + scrollTop >= container) {
          if (!(number >= array_app.length)) {
            index = index + 3;
            number = number + 3;
            this.callAppartement(array_app, app_section, index, number);
          }
        }
      });
    }
  }
  callAppartement(array_app, app_section, index, number) {
    for (index; index < number; index++) {
      app_section.innerHTML += array_app[index];
    }
    var $text = $(".s_appartement");
    $text.html($text.html().replace("undefined", " "));
  }
  callCountAppartement(countAppartement) {
    let numberAppartement = document.querySelector(".s_text_filter > span > b");
    numberAppartement.innerHTML = countAppartement;
  }
  callModelMap() {
    const city = document.querySelector(".cp").innerHTML;
    console.log(city);
    let longitude;
    let latitude;
    const callCity =
      "https://api-adresse.data.gouv.fr/search/?q=" +
      city +
      "&type=municipality&autocomplete=1";
    $.ajax({
      url: callCity,
      dataType: "json",
      success: function (data) {
        longitude = data.features[0].geometry.coordinates[0];
        latitude = data.features[0].geometry.coordinates[1];

        const geojson = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                message: "Von de kay",
                iconSize: [200, 200],
              },
              geometry: {
                type: "Point",
                coordinates: [longitude, latitude],
              },
            },
          ],
        };
        const map = new mapboxgl.Map({
          container: "ModelMap", // container ID
          style: "mapbox://styles/cstacky/ckwurmvvq439x14o1t4wzu6r0", // style URL
          center: [longitude, latitude], // starting position
          zoom: 12, // starting zoom
        });

        map.doubleClickZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.scrollZoom.disable();
        map.touchZoomRotate.disable();

        for (const marker of geojson.features) {
          // Create a DOM element for each marker.
          const el = document.createElement("div");
          const width = marker.properties.iconSize[0];
          const height = marker.properties.iconSize[1];
          el.className = "marker";

          //   el.style.backgroundImage = `url(/client/von-de-kay/assets/svg/cercle.svg)`;
          el.style.backgroundImage = `url(/assets/svg/cercle.svg)`;
          el.style.width = `${width}px`;
          el.style.height = `${height}px`;
          el.style.backgroundSize = "100%";

          // el.addEventListener('click', () => {
          // window.alert(marker.properties.message);
          // });

          // Add markers to the map.
          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
        }
      },
    });
  }
}

class estimationCall {
  callCheckAdresse() {
    if (localStorage.getItem("adresse") === null) {
      document.location.href = "/";
    } else {
      document.querySelector("body").style.display = "block";
    }
  }
  callStartEstimation() {
    let buttonEstimation = document.getElementById("start_estimation");
    String.prototype.isNumber = function () {
      return /^\d+$/.test(this);
    };
    buttonEstimation.addEventListener("click", function (event) {
      $(".error_message").remove();
      let contentAdresse = document.getElementById("adresse").value;
      const arrayAdresse = contentAdresse.split(" ");

      if (arrayAdresse[0].match(/^[0-9]+$/) != null) {
        if (arrayAdresse.length > 3) {
          if (localStorage.getItem("adresse") === null) {
            localStorage.setItem("adresse", contentAdresse);
            document.location.href = "/estimer-en-ligne";
          } else {
            localStorage.setItem("temp_adresse", contentAdresse);
            let lastAdresse = localStorage.getItem("adresse");
            document.querySelector(".s_verification_adresse").style.display =
              "flex";
            document.querySelector(
              ".s_verification_adresse div > p > b"
            ).innerHTML = lastAdresse;

            document
              .querySelector(".s_reload")
              .addEventListener("click", function () {
                localStorage.clear();
                localStorage.setItem("currentStep", 1);
                localStorage.setItem("adresse", contentAdresse);
                document.location.href = "/estimer-en-ligne";
              });
            document
              .querySelector(".s_continue")
              .addEventListener("click", function () {
                document.location.href = "/estimer-en-ligne";
              });
          }
        } else {
          $("#s_message_error").show();
          $("#s_message_error").append(
            '<div class="error_message">Le format de votre adresse est invalide, vous devez sélectionner une des propositions disponibles.</div>'
          );
        }
      } else {
        $("#s_message_error").show();
        $("#s_message_error").append(
          '<div class="error_message">Vous devez renseigner le numéro de l\'adresse.</div>'
        );
      }
    });
  }
  callEstimationBox() {
    // this.callHistoryStep();
    let currentStep = parseInt(localStorage.getItem("currentStep"));
    let textExpert = document.querySelector(".s_expert > div:last-child p");
    var buttonSubmit = document.getElementById("submit");
    var buttonReturn = document.getElementById("return");
    switch (currentStep) {
      case 1:
        var boxEstimation = document.querySelectorAll(
          ".s_select_option > div > div"
        );
        buttonReturn.style.display = "none";
        buttonSubmit.style.display = "none";
        textExpert.innerHTML = "Est-ce une maison ou un appartement ?";
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        boxEstimation.forEach((item, index) => {
          item.addEventListener("click", () => {
            buttonSubmit.style.display = "block";
            if (index === 0) {
              boxEstimation[1].style.border = "2px solid #eee";
              item.style.border = "2px solid #325a66";
              localStorage.setItem("step_1", "Une maison");
            }
            if (index === 1) {
              boxEstimation[0].style.border = "2px solid #eee";
              item.style.border = "2px solid #325a66";
              localStorage.setItem("step_1", "Un appartement");
            }
          });
        });
        break;
      case 2:
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        textExpert.innerHTML =
          localStorage.getItem("step_1") +
          ", c’est noté ! Détaillons maintenant votre bien.";
        buttonReturn.style.display = "block";
        buttonSubmit.style.display = "block";
        if (localStorage.getItem("step_1") === "Une maison") {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "flex";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "none";
        } else if (currentStep != 6) {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "none";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "flex";
        }
        break;
      case 3:
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        textExpert.innerHTML =
          "Merci, nous avons besoin de quelques détails sur votre bien.";
        buttonReturn.style.display = "block";
        buttonSubmit.style.display = "block";
        if (localStorage.getItem("step_1") === "Une maison") {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "flex";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "none";
        } else if (currentStep != 6) {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "none";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "flex";
        }
        break;
      case 4:
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        textExpert.innerHTML =
          "Encore quelques caractéristiques supplémentaires.";
        buttonReturn.style.display = "block";
        buttonSubmit.style.display = "block";
        if (localStorage.getItem("step_1") === "Une maison") {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "flex";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "none";
        } else if (currentStep != 6) {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "none";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "flex";
        }
        break;
      case 5:
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        textExpert.innerHTML =
          "Pour finir, nous avons besoin de savoir si vous êtes propriétaire du bien.";
        buttonReturn.style.display = "block";
        buttonSubmit.style.display = "block";
        document.querySelector(".main_panel").style.display = "flex";

        if (localStorage.getItem("step_1") === "Une maison") {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "flex";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "none";
        } else if (currentStep != 6) {
          document.querySelector(
            ".s_step_" + currentStep + " .maison"
          ).style.display = "none";
          document.querySelector(
            ".s_step_" + currentStep + " .appartement"
          ).style.display = "flex";
        }
        break;
      case 6:
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        textExpert.innerHTML = "Votre estimation est prête !";
        buttonReturn.style.display = "none";
        document.querySelector(".main_panel").style.display = "none";
        break;
      case 7:
        document.querySelector(".s_step_" + currentStep).style.display = "flex";
        textExpert.innerHTML =
          "Merci d'avoir partagé ces informations. <br> Nous revenons vers vous sous 48heures. <br><br>Bien cordialement.";
        buttonReturn.style.display = "none";
        document.querySelector(".main_panel").style.display = "none";
        break;
    }
  }
  callReturnStage() {
    let returnStage = document.querySelector(".real > a:first-child");
    let returnStageFinal = document.getElementById("returnFinal");
    returnStageFinal.addEventListener("click", () => {
      // for (let index = 1; index < currentStep; index++) {
      //     document.querySelector(".history_" + index).remove();
      // }

      let currentStep = parseInt(localStorage.getItem("currentStep"));
      currentStep--;
      localStorage.setItem("currentStep", currentStep);
      this.callReloadStepDisplay();
      this.callEstimationBox();
      this.callConstructLineProgress();
      location.reload();
    });
    returnStage.addEventListener("click", () => {
      // for (let index = 1; index < currentStep; index++) {
      //     document.querySelector(".history_" + index).remove();
      // }

      let currentStep = parseInt(localStorage.getItem("currentStep"));
      currentStep--;
      localStorage.setItem("currentStep", currentStep);
      this.callReloadStepDisplay();
      this.callEstimationBox();
      this.callConstructLineProgress();
      location.reload();
    });
  }
  // callHistoryStep() {
  //     let currentStep = parseInt(localStorage.getItem("currentStep"));
  //     document.querySelector('.history_adresse > p').innerHTML = localStorage.getItem("adresse");
  //     for (let index = 1; index < currentStep; index++) {
  //       var newDiv = document.createElement("div");
  //       newDiv.className = "history_" + index;
  //       var currentDiv = document.getElementById("history_content");
  //       var newSpan = document.createElement("span");
  //       var newPara = document.createElement("p");

  //       newSpan.innerHTML = "ETAPE " + (index);

  //       switch(index) {
  //         case 1:
  //           newPara.innerHTML = "Vous avez " + localStorage.getItem("step_1") + ".";
  //           break;
  //         case 2:
  //           if(localStorage.getItem("step_1") === "Une maison") {
  //             newPara.innerHTML = "Votre maison à été construire " + localStorage.getItem("step_2") + " avec une superficie de " + localStorage.getItem("step_3") + "㎡.<br><br>Piece : " + localStorage.getItem("step_4_0") + "<br>Chambre : " + localStorage.getItem("step_4_1") + "<br>Salle de douche/bain : " + localStorage.getItem("step_4_2") + "<br><br> Vous estimez que votre bien est " + localStorage.getItem("step_5");
  //           } else {
  //             if(localStorage.getItem('step_2') === "1") {
  //               newPara.innerHTML = "Vous êtes au " + localStorage.getItem("step_2") + "er étage. <br><br>Vous avez un appartement de " + localStorage.getItem("step_3") + "㎡.<br><br>Piece : " + localStorage.getItem("step_4_0") + "<br>Chambre : " + localStorage.getItem("step_4_1") + "<br>Salle D'eau : " + localStorage.getItem("step_4_2") + ".<br><br> "+ localStorage.getItem("step_5");
  //             } else {
  //               newPara.innerHTML = "Vous êtes au " + localStorage.getItem("step_2") + "eme étage. <br><br>Vous avez un appartement de " + localStorage.getItem("step_3") + "㎡.<br><br>Piece : " + localStorage.getItem("step_4_0") + "<br>Chambre : " + localStorage.getItem("step_4_1") + "<br>Salle D'eau : " + localStorage.getItem("step_4_2") + ".<br><br> "+ localStorage.getItem("step_5");
  //             }
  //           }
  //           break;
  //         case 3:
  //           if(localStorage.getItem("step_1") === "Une maison") {
  //             step_7 = "Cave : " + localStorage.getItem("step_7");

  //             if(localStorage.getItem("step_6_0") === "Aucune surface") {
  //               step_6 = "<br>Vous n'avez aucuns espaces extérieurs";
  //             } else {
  //               step_6 = "<br>" +localStorage.getItem("step_6_1") + " de " + localStorage.getItem("step_6_0") + "㎡"
  //             }

  //             newPara.innerHTML = step_7 + step_6;

  //           } else {
  //             if(localStorage.getItem("step_6_0") === "Aucune surface") {
  //               newPara.innerHTML = "Vous n'avez aucuns espaces extérieurs";
  //             } else {
  //               newPara.innerHTML = localStorage.getItem("step_6_1") + " de " + localStorage.getItem("step_6_0") + "㎡"
  //             }
  //             if(localStorage.getItem("step_7") === "Vis-à-vis") {
  //               newPara.innerHTML = newPara.textContent + " et il y'a du vis-à-vis.";
  //             } else {
  //               newPara.innerHTML = newPara.textContent + " et il y'a une vue " + localStorage.getItem("step_7") + ".";
  //             }
  //           }
  //           break;
  //         case 4:
  //           if(localStorage.getItem("step_1") === "Une maison") {
  //             step_8 = "Exposition : " + localStorage.getItem("step_8");
  //             step_9 = "<br>Vue : " + localStorage.getItem("step_9");
  //             step_10 = "<br>Emplacement : " + localStorage.getItem("step_10");
  //             newPara.innerHTML = step_8 + step_9 + step_10;
  //           } else {
  //             let step_9_0 = "";
  //             let step_9_1 = "";
  //             let step_9_2 = "";

  //             if(localStorage.getItem('step_9_0') === "true") {
  //               step_9_0 = "Vous avez une cave de " + localStorage.getItem('step_9_0_0') + " ㎡.<br>";
  //             } else {
  //               step_9_0 = "Vous avez une cave.<br>";
  //             }

  //             if(localStorage.getItem('step_9_1') === "true") {
  //               step_9_1 = newPara.textContent + "Vous avez une place de parking de " + localStorage.getItem('step_9_1_0') + " ㎡.<br>";
  //             } else {
  //               step_9_1 = newPara.textContent + "Vous n'avez pas de place de parking.<br>";
  //             }

  //             if(localStorage.getItem('step_9_2') === "true") {
  //               step_9_2 = newPara.textContent + "Vous avez une chambre de service de " + localStorage.getItem('step_9_2_0') + " ㎡.";
  //             } else {
  //               step_9_2 = newPara.textContent + "Vous n'avez pas de chambre de service.";
  //             }

  //             newPara.innerHTML = "Vous qualifieriez votre emplacement de " + localStorage.getItem("step_8") + " <br><br> " + step_9_0 + "" + step_9_1 + "" + step_9_2 + "<br><br>Vous avez " + localStorage.getItem('step_10') + "euros de charges mensuelles.";
  //           }
  //           break;
  //         case 5:
  //           if(localStorage.getItem('step_11') === "Oui") {
  //             newPara.innerHTML = "Vous êtes propriétaire de ce bien.";
  //           } else {
  //             newPara.innerHTML = "Vous n'êtes pas propriétaire de ce bien.";
  //           }
  //           break;
  //           case 5:
  //             newPara.innerHTML = "Nous revenonns vers vous sous 48heures.";
  //             break;
  //       }
  //       newDiv.appendChild(newSpan);
  //       newDiv.appendChild(newPara);
  //       currentDiv.appendChild(newDiv);
  //     }
  // }
  callEstimationStep() {
    let nextStage = document.querySelector(".real > a:last-child");
    nextStage.addEventListener("click", () => {
      let currentStep = parseInt(localStorage.getItem("currentStep"));
      // for (let index = 1; index < currentStep; index++) {
      // document.querySelector(".history_" + index).remove();

      // }
      var buttonSubmit = document.getElementById("submit");
      var buttonReturn = document.getElementById("return");
      switch (currentStep) {
        case 1:
          currentStep++;
          localStorage.setItem("currentStep", currentStep);
          buttonSubmit.style.display = "none";
          document.querySelector(".s_step_" + (currentStep - 1)).style.display =
            "none";
          document.querySelector(".s_step_" + currentStep).style.display =
            "flex";
          break;
        case 2:
          currentStep++;
          localStorage.setItem("currentStep", currentStep);
          let etat_appartement =
            document.getElementById("etat_maison_app").value;
          let superfice = document.getElementById("superfice_app").value;
          let piece = document.getElementById("piece_app").value;
          let chambre = document.getElementById("chambre_app").value;
          let salle_eau = document.getElementById("salle_eau_app").value;
          // let etage = document.getElementById('etage_app').value;
          let construction_maison = document.getElementById(
            "annee_construction_maison"
          ).value;
          let superficie_maison =
            document.getElementById("superfice_maison").value;

          let piece_maison = document.getElementById("piece_maison").value;
          let chambre_maison = document.getElementById("chambre_maison").value;
          let salle_eau_maison =
            document.getElementById("salle_eau_maison").value;

          let etat_maison = document.getElementById("etat_maison").value;

          if (localStorage.getItem("step_1") === "Une maison") {
            localStorage.setItem("step_2", construction_maison);
            localStorage.setItem("step_3", superficie_maison);
            localStorage.setItem("step_4_0", piece_maison);
            localStorage.setItem("step_4_1", chambre_maison);
            localStorage.setItem("step_4_2", salle_eau_maison);
            localStorage.setItem("step_5", etat_maison);
          } else {
            // localStorage.setItem('step_2', etage);
            localStorage.setItem("step_3", superfice);
            localStorage.setItem("step_4_0", piece);
            localStorage.setItem("step_4_1", chambre);
            localStorage.setItem("step_4_2", salle_eau);
            localStorage.setItem("step_5", etat_appartement);
          }

          document.querySelector(".s_step_" + (currentStep - 1)).style.display =
            "none";
          document.querySelector(".s_step_" + currentStep).style.display =
            "flex";

          break;
        case 3:
          let surface_exterrieur = document.getElementById(
            "surface_exterrieur_app"
          ).value;
          let vue_appartement = document.getElementById("vue_maison_app").value;
          let cave_maison = document.getElementById("cave_maison").value;
          currentStep++;
          localStorage.setItem("currentStep", currentStep);

          if (localStorage.getItem("step_1") === "Une maison") {
            if (surface_exterrieur != 0) {
              localStorage.setItem("step_6_0", surface_exterrieur);
            } else {
              localStorage.setItem("step_6_0", "Aucune surface");
            }
            localStorage.setItem("step_7", cave_maison);
          } else {
            if (surface_exterrieur != 0) {
              localStorage.setItem("step_6_0", surface_exterrieur);
            } else {
              localStorage.setItem("step_6_0", "Aucune surface");
            }
            localStorage.setItem("step_7", vue_appartement);
          }

          document.querySelector(".s_step_" + (currentStep - 1)).style.display =
            "none";
          document.querySelector(".s_step_" + currentStep).style.display =
            "flex";
          break;
        case 4:
          currentStep++;
          localStorage.setItem("currentStep", currentStep);

          let estimation_emplacement_bruit = document.getElementById(
            "estimation_emplacement_bruit_app"
          ).value;
          let cave = document.getElementById("cave_app").checked;
          let parking = document.getElementById("parking_app").checked;
          let chambre_de_service = document.getElementById(
            "chambre-de-service_app"
          ).checked;
          let superficie_cave = document.getElementById(
            "superficie_cave_app"
          ).value;
          let superficie_parking = document.getElementById(
            "superficie_parking_app"
          ).value;
          let superficie_chambre_de_service = document.getElementById(
            "superficie_chambre_de_service_app"
          ).value;
          let charge = document.getElementById("charge_app").value;
          let exposition_maison =
            document.getElementById("exposition_maison").value;
          let vue_maison = document.getElementById("vue_maison").value;
          let emplacement_maison =
            document.getElementById("emplacement_maison").value;

          if (localStorage.getItem("step_1") === "Une maison") {
            localStorage.setItem("step_8", exposition_maison);
            localStorage.setItem("step_9", vue_maison);
            localStorage.setItem("step_10", emplacement_maison);
          } else {
            localStorage.setItem("step_8", estimation_emplacement_bruit);
            localStorage.setItem("step_9_0", cave);
            localStorage.setItem("step_9_1", parking);
            localStorage.setItem("step_9_2", chambre_de_service);
            if (superficie_cave != 0) {
              localStorage.setItem("step_9_0_0", superficie_cave);
            } else {
              localStorage.setItem("step_9_0_0", "Aucune surface");
            }
            if (superficie_parking != 0) {
              localStorage.setItem("step_9_1_0", superficie_parking);
            } else {
              localStorage.setItem("step_9_1_0", "Aucune surface");
            }
            if (superficie_chambre_de_service != 0) {
              localStorage.setItem("step_9_2_0", superficie_chambre_de_service);
            } else {
              localStorage.setItem("step_9_2_0", "Aucune surface");
            }
            localStorage.setItem("step_10", charge);
          }

          document.querySelector(".s_step_" + (currentStep - 1)).style.display =
            "none";
          document.querySelector(".s_step_" + currentStep).style.display =
            "flex";
          break;
        case 5:
          currentStep++;
          localStorage.setItem("currentStep", currentStep);

          let proprietaire_app =
            document.getElementById("proprietaire_app").value;
          let proprietaire_maison = document.getElementById(
            "proprietaire_maison"
          ).value;

          if (localStorage.getItem("step_1") === "Une maison") {
            localStorage.setItem("step_11", proprietaire_maison);
          } else {
            localStorage.setItem("step_11", proprietaire_app);
          }

          document.querySelector(".s_step_" + (currentStep - 1)).style.display =
            "none";
          document.querySelector(".s_step_" + currentStep).style.display =
            "flex";
          break;
        case 6:
          currentStep++;
          localStorage.setItem("currentStep", currentStep);

          buttonReturn.style.display = "none";
          document.querySelector(".main_panel").style.display = "none";

          document.querySelector(".s_step_" + (currentStep - 1)).style.display =
            "none";
          document.querySelector(".s_step_" + currentStep).style.display =
            "flex";
          break;
      }
      this.callEstimationBox();
      this.callConstructLineProgress();
    });
  }
  callGetSelectStep9() {
    const steps9 = document.querySelectorAll(".s_st9");
    steps9.forEach((step9) => {
      step9.addEventListener("change", () => {
        let cave = document.getElementById("cave_app").checked;
        let parking = document.getElementById("parking_app").checked;
        let chambre_de_service = document.getElementById(
          "chambre-de-service_app"
        ).checked;

        if (cave == true) {
          document.querySelector(
            "#superficie_cave_app"
          ).parentNode.style.display = "flex";
        }
        if (parking == true) {
          document.querySelector(
            "#superficie_parking_app"
          ).parentNode.style.display = "flex";
        }
        if (chambre_de_service == true) {
          document.querySelector(
            "#superficie_chambre_de_service_app"
          ).parentNode.style.display = "flex";
        }
        if (cave != true) {
          document.querySelector(
            "#superficie_cave_app"
          ).parentNode.style.display = "none";
          document.querySelector("#superficie_cave_app").value = 0;
        }
        if (parking != true) {
          document.querySelector(
            "#superficie_parking_app"
          ).parentNode.style.display = "none";
          document.querySelector("#superficie_parking_app").value = 0;
        }
        if (chambre_de_service != true) {
          document.querySelector(
            "#superficie_chambre_de_service_app"
          ).parentNode.style.display = "none";
          document.querySelector(
            "#superficie_chambre_de_service_app"
          ).value = 0;
        }
      });
    });
  }
  callGetSelectStep6() {
    const steps6 = document.querySelectorAll(".s_st6");
    steps6.forEach((step6) => {
      step6.addEventListener("change", () => {
        let currentStep = parseInt(localStorage.getItem("currentStep"));
        if (
          step6.value === "Balcon" ||
          step6.value === "Terrasse" ||
          step6.value === "Jardin"
        ) {
          document.querySelector(
            ".s_step_" + currentStep + " .s_step_6_number"
          ).style.display = "flex";
          document.querySelector(
            ".s_step_" + currentStep + " .s_step_6_number span"
          ).innerHTML = "Quelle est la surface de votre " + step6.value + " ?";
        } else if (step6.value === "Aucuns") {
          document.querySelector(
            ".s_step_" + currentStep + " .s_step_6_number"
          ).style.display = "none";
          document.getElementById("surface_exterrieur_app").value = 0;
        }
        localStorage.setItem("step_6_1", step6.value);
      });
    });
  }
  // callClearLocalStorage() {
  //     let clearLocal = document.querySelector('.s_step_7 > a');
  //     clearLocal.addEventListener('click', () => {
  //         localStorage.clear();
  //     });
  // }
  callReloadStepDisplay() {
    for (let i = 1; i < 7; i++) {
      document.querySelector(".s_step_" + i).style.display = "none";
    }
  }
  callConstructLineProgress() {
    if (localStorage.getItem("currentStep") === null) {
      localStorage.setItem("currentStep", 1);
    }
    let currentStep = parseInt(localStorage.getItem("currentStep"));
    let calculWidth = 100 / 7;
    if (!construct) {
      var div = document.createElement("div");
      var span = document.createElement("span");
      document.querySelector(".s_line").appendChild(div);
      document.querySelector(".s_line div").classList.add("s_steps", "current");
      div.appendChild(span);
      construct = true;
    }
    document.querySelector(".current").style.width =
      currentStep * calculWidth + "%";
    if (currentStep === 7) {
      document.querySelector(".s_steps span").innerHTML = "";
    } else {
      document.querySelector(".s_steps span").innerHTML =
        currentStep + " sur 7";
    }
  }
}

class Animation {
  callGetAbsolutePositionYTop(el) {
    if (document.getElementById(el) != null) {
      return (
        document.getElementById(el).getBoundingClientRect().top +
        document.documentElement.scrollTop -
        1
      );
    }
  }
  callGetAbsolutePositionYBottom(el) {
    if (document.getElementById(el) != null) {
      return (
        document.getElementById(el).getBoundingClientRect().bottom +
        document.documentElement.scrollTop -
        1
      );
    }
  }

  callParallax() {
    const body = document.body;
    const html = document.documentElement;
    let images = document.querySelectorAll(".s_model_content > div > img");
    let fixVisual = 600;

    // let topDecorationFirst = this.callGetAbsolutePositionYTop('decoration_0');
    // let bottomDecorationFirst = this.callGetAbsolutePositionYBottom('decoration_0');

    // let topDecorationSecond = this.callGetAbsolutePositionYTop('decoration_1');
    // let bottomDecorationSecond = this.callGetAbsolutePositionYBottom('decoration_1');

    // let topDecorationThird = this.callGetAbsolutePositionYTop('decoration_2');
    // let bottomDecorationThird = this.callGetAbsolutePositionYBottom('decoration_2');

    // let topDecorationFour = this.callGetAbsolutePositionYTop('decoration_3');
    // let bottomDecorationFour = this.callGetAbsolutePositionYBottom('decoration_3');
    // //POUR FAIRE DEMARER L'ANIMATION PLUS TARD AU SCOLL
    window.addEventListener("scroll", () => {
      if (
        window.innerHeight + window.scrollY >=
          this.callGetAbsolutePositionYTop(0 + "_image") + fixVisual &&
        window.innerHeight + window.scrollY <=
          this.callGetAbsolutePositionYBottom(0 + "_image") + fixVisual
      ) {
        images[0].style.bottom =
          Math.abs(
            this.callGetAbsolutePositionYTop(0 + "_image") -
              (window.innerHeight + window.scrollY) +
              fixVisual
          ) + "px";
        console.log(
          Math.abs(
            this.callGetAbsolutePositionYTop(0 + "_image") -
              (window.innerHeight + window.scrollY) +
              fixVisual
          ) + "px"
        );
      }
    });
  }

  callStandardAnime() {
    const scrollElements = document.querySelectorAll(".anime");
    const elementInView = (el, dividend = 1) => {
      const elementTop = el.getBoundingClientRect().top;
      return (
        elementTop <=
        (window.innerHeight || document.documentElement.clientHeight) / dividend
      );
    };
    const elementOutofView = (el) => {
      const elementTop = el.getBoundingClientRect().top;
      return (
        elementTop >
        (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    const displayScrollElement = (element) => {
      element.classList.add("animed");
    };

    const hideScrollElement = (element) => {
      element.classList.remove("animed");
    };

    const handleScrollAnimation = () => {
      scrollElements.forEach((el) => {
        if (elementInView(el, 1.25)) {
          displayScrollElement(el);
        } else if (elementOutofView(el)) {
          hideScrollElement(el);
        }
      });
    };

    window.addEventListener("scroll", () => {
      handleScrollAnimation();
    });
  }
}

class accueilPannel {
  displayImageForm() {
    let button = document.querySelector(".image_form_btn");
    let form = document.querySelector(".image_form");
    button.addEventListener("click", () => {
      form.style.display == "flex"
        ? (form.style.display = "none")
        : (form.style.display = "flex");
    });
  }
}

const appartment = new Appartement();
const general = new generalCall();
const navigation = new navigationCall();
const loader = new loaderCall();
const slide = new slideCall();
const ajax = new ajaxCall();
const estimation = new estimationCall();
const animation = new Animation();
const accueilpannel = new accueilPannel();

general.callMenu();
general.callAxeptio();
loader.callInit();
animation.callStandardAnime();

// CALL SPECIFIC SCRIPT

switch (location.pathname.split("/")[1]) {
  case "":
    navigation.callInit(1);
    slide.initCall();
    general.callApiGouv(false);
    estimation.callStartEstimation();
    general.logicHome();
    break;
  case "votre-accueil":
    accueilpannel.displayImageForm();
    slide.initCall();
    general.logicHome();
    navigation.callInit(3);

    break;
  case "accueil":
    navigation.callInit(1);
    slide.initCall();
    general.callApiGouv(false);
    estimation.callStartEstimation();
    break;
  case "estimer-votre-bien":
    navigation.callInit(2);
    slide.initCall();
    general.callApiGouv(false);
    estimation.callStartEstimation();
    break;
  case "nos-equipes":
    navigation.callInit(3);
    // slide.callThumbnails();
    general.callAnimation(1);
    navigation.callInit(3);
    slide.initCall();
    break;
  case "nous-contacter":
    navigation.callInit(3);
    slide.initCall();
    ajax.callContact();
    break;
  case "acheter":
  case "louer":
    navigation.callInit(3);
    slide.initCall();
    appartment.initCall();
    appartment.callInfinityScroll(contentAppartement);
    appartment.callMenuFilter();
    general.callApiGouv(true);
    break;
  case "connexion":
    navigation.callInit(3);
    slide.initCall();
    ajax.callConnexionClient();
    break;
  case "mandat-platinium":
  case "actualites":
  case "tableau-board":
  case "newsletter":
  case "mentions-legals":
  case "felicitation":
  case "politiques-utilisation-des-donnees":
    navigation.callInit(3);
    slide.initCall();
    break;
  case "vos-blogs":
    navigation.callInit(3);
    slide.initCall();
    ajax.callAddNewBlog();
    ajax.callEditNewBlog();
    general.callEditor();
    break;
  case "recherche":
    general.callZoomImage();
    navigation.callInit(3);
    slide.initCall();
    appartment.callModelMap();
    break;
  case "vos-equipes":
    navigation.callInit(3);
    slide.initCall();
    ajax.callAddNewEquipe();
    ajax.callEditNewEquipe();
    break;
  case "estimer-en-ligne":
    navigation.callInit(3);
    slide.initCall();
    ajax.callEstimation();
    estimation.callCheckAdresse();
    estimation.callConstructLineProgress();
    estimation.callEstimationBox();
    // estimation.callClearLocalStorage();

    estimation.callEstimationStep();
    estimation.callReturnStage();
    estimation.callGetSelectStep9();
    estimation.callGetSelectStep6();
    break;
}
